import { GroupButtons, Select } from '@weareredlight/design-system'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { OrganizationAppointmentsParams } from 'types/appointments'
import type { OrganizationType } from 'types/organizations'
import type { TreatmentsType } from 'types/treatments'
import type { PaginatedRequest } from 'types/types'

import CalendarView from '../../components/Treatments/CalendarView'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import ScheduleNavigation from 'components/Schedule/ScheduleNavigation'
import TreatmentsList from 'components/Treatments/TreatmentsList'
import useDateParams from 'hooks/useDateParams'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { CalendarViews, ScheduleViews } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { treatmentsColumns } from 'utils/tables/treatments'

type Option = { id: string; name: string }

const ScheduleView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()
  const [customDate, setCustomDate] = useState(new Date().toISOString())
  const { currentWeek, currentYear } = useDateParams(customDate)

  const [calendarFilters, setCalendarFilters] =
    useState<OrganizationAppointmentsParams>()
  const [scheduleView, setScheduleView] = useState<ScheduleViews>(
    ScheduleViews.CALENDAR,
  )
  const [calendarView, setCalendarView] = useState<CalendarViews>(
    CalendarViews.WEEK,
  )
  const [selectedOrg, setSelectedOrg] = useState('')
  const [openFilters, setOpenFilters] = useState(false)

  const handleCustomDateChange = (date: string) => {
    if (date && dayjs(date).isValid()) setCustomDate(date)
  }

  const { data: organizationsData, doRequest: getOrganizations } = useRequest<
    PaginatedRequest<OrganizationType>
  >(api.getOrganizations)

  const treatmentParams = useMemo(
    () => ({
      week: currentWeek || 0,
      year: currentYear || 0,
      organizationId: selectedOrg || '',
    }),
    [currentYear, selectedOrg, currentWeek],
  )

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  return (
    <PageTemplate
      title={t('Schedule')}
      leftActions={
        // TODO:
        // - If the user is a Scheduling Admin, he will be able to select a different org (BioX Colombia should be the defautlt one)
        // - Other users will only see the org they belong to
        isAdmin && (
          <Select
            id="select"
            placeholder={t('Please select an organization')}
            value={selectedOrg}
            options={organizationsData?.data || []}
            onChange={e => setSelectedOrg(e.target.value)}
            getLabel={(option: Option) => option.name}
            getValue={(option: Option) => option.id}
            css={{
              button: {
                include: 'paragraph',
                minWidth: 'unset',
                width: 'fit-content',
                border: 'none',
                color: '$primary',
              },
              'span:last-child': {
                marginLeft: '$xxsm',
              },
            }}
          />
        )
      }
      rightActions={
        <GroupButtons
          buttons={enum2SelectOptions(ScheduleViews)}
          selectedButton={scheduleView}
          onButtonSelect={value => setScheduleView(value as ScheduleViews)}
        />
      }
    >
      <ScheduleNavigation
        scheduleView={scheduleView}
        calendarView={calendarView}
        updateCalendarView={value => setCalendarView(value as CalendarViews)}
        getShowFilters={setOpenFilters}
        showFilters={openFilters}
        customDate={customDate}
        setCustomDate={handleCustomDateChange}
        calendarFilters={calendarFilters}
      />
      {scheduleView === ScheduleViews.LIST ? (
        <TreatmentsList
          columns={treatmentsColumns as TreatmentsType[]}
          params={treatmentParams}
          onRowClick={data => {
            navigate(`${RootPath.SCHEDULE}/${data?.id}`)
          }}
        />
      ) : (
        <CalendarView
          selectedOrg={
            organizationsData?.data.find(
              organization => organization.id === selectedOrg,
            ) || ({} as OrganizationType)
          }
          defaultDate={customDate}
          showFilters={openFilters}
          view={calendarView}
          calendarFilters={calendarFilters}
          setCalendarFilters={setCalendarFilters}
        />
      )}
    </PageTemplate>
  )
}

export default ScheduleView
