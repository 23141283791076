import { Button, Flex, Text } from '@weareredlight/design-system'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { AppointmentType } from 'types/appointments'
import type { ProcedureWithAppointmentType } from 'types/procedures'
import type { TreatmentType } from 'types/treatments'

import { ReactComponent as MagicIcon } from '../../assets/img/magic.svg'

import Card from 'components/Card'
import Table from 'components/Table'
import CancellationReasons from 'components/Treatments/CancellationReasons'
import CancelTreatmentProcedures from 'components/Treatments/CancelTreatmentProcedures'
import NewTreatmentProcedures from 'components/Treatments/NewTreatmentProcedures'
import RestoreTreatmentProcedures from 'components/Treatments/RestoreTreatmentProcedures'
import ScheduleAssistTreatment from 'components/Treatments/ScheduleAssistTreatment'
import StatusTag from 'components/Treatments/StatusTag'
import TreatmentCancellation from 'components/Treatments/TreatmentCancellation'
import { useSchedule } from 'pages/Schedule/ScheduleAppointment'
import { useCurrentUser } from 'userContext'
import { treatmentProceduresColumns } from 'utils/tables/treatmentProcedures'
import { getName } from 'utils/text'

const TreatmentAppointmentsList = () => {
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()
  const {
    treatment,
    appointments,
    isLoading,
    updateTreatment,
    isCancelledTreatment,
  } = useSchedule()

  const [proceduresModalOpen, setProceduresModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [
    treatmentScheduleAssistModalOpen,
    setTreatmentScheduleAssistModalOpen,
  ] = useState(false)
  const [procedureToCancel, setProcedureToCancel] = useState<string | null>(
    null,
  )
  const [procedureToRestore, setProcedureToRestore] = useState<string | null>(
    null,
  )

  // Nest Procedures with Appointments
  const proceduresWithAppointments: ProcedureWithAppointmentType[] =
    useMemo(() => {
      if (!treatment?.procedures || !appointments?.data) return []
      return treatment?.procedures?.map(({ id, status, procedure }) => {
        const appointment = appointments?.data.find(
          appointment => appointment?.treatmentProcedure.id === id,
        )
        return {
          ...procedure,
          status,
          treatmentProcedureId: id,
          appointment: appointment || ({} as AppointmentType),
        }
      })
    }, [appointments?.data, treatment?.procedures])

  return (
    <Card extraClasses="wrapper full-width" isLoading={isLoading}>
      <Flex direction="column" gap="xlg">
        <Flex justify="spaceBetween" css={{ width: '100%' }} gap="xxsm" wrap>
          <Flex gap="xxsm" justify="start">
            <Text variant="h3" color="accent">
              {getName(treatment?.patient)}
            </Text>
            {treatment?.status && <StatusTag status={treatment.status} />}
          </Flex>
          {!isCancelledTreatment && isAdmin && (
            <Flex gap="xxsm" justify="start" wrap>
              <Button
                variant="neutral"
                onClick={() => setCancelModalOpen(!cancelModalOpen)}
              >
                {t('Cancel Treatment')}
              </Button>
              <Button
                onClick={() => setProceduresModalOpen(!proceduresModalOpen)}
              >
                {t('Add Procedures')}
              </Button>
              <Button
                variant="success"
                iconComponent={() => <MagicIcon />}
                onClick={() =>
                  setTreatmentScheduleAssistModalOpen(
                    !treatmentScheduleAssistModalOpen,
                  )
                }
              >
                {t('Schedule Assist')}
              </Button>
            </Flex>
          )}
        </Flex>
        {isCancelledTreatment && treatment?.cancellationReason && (
          <CancellationReasons
            cancellationReason={treatment?.cancellationReason}
          />
        )}
        <Table<ProcedureWithAppointmentType>
          data={proceduresWithAppointments || []}
          columns={treatmentProceduresColumns(
            setProcedureToCancel,
            setProcedureToRestore,
          )}
          totalCount={treatment?.procedures.length || 0}
          totalPages={0}
          hiddenColumns={isCancelledTreatment || !isAdmin ? ['id'] : []}
        />
        <NewTreatmentProcedures
          isOpen={proceduresModalOpen}
          handleOpen={setProceduresModalOpen}
          treatmentId={String(treatment?.id)}
          refreshData={updateTreatment}
        />
        <TreatmentCancellation
          isOpen={cancelModalOpen}
          handleOpen={setCancelModalOpen}
          treatment={treatment as TreatmentType}
          refreshData={updateTreatment}
        />
        <CancelTreatmentProcedures
          treatmentId={String(treatment?.id)}
          procedureId={procedureToCancel}
          onClose={setProcedureToCancel}
          refreshData={updateTreatment}
        />
        <RestoreTreatmentProcedures
          treatmentId={String(treatment?.id)}
          procedureId={procedureToRestore}
          onClose={setProcedureToRestore}
          refreshData={updateTreatment}
        />
        <ScheduleAssistTreatment
          treatmentId={String(treatment?.id)}
          isOpen={treatmentScheduleAssistModalOpen}
          handleOpen={setTreatmentScheduleAssistModalOpen}
          refreshData={updateTreatment}
        />
      </Flex>
    </Card>
  )
}

export default TreatmentAppointmentsList
