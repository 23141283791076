import { createColumnHelper } from '@tanstack/react-table'
import i18next from 'i18next'

import type { TreatmentsType } from 'types/treatments'

import StatusTag from 'components/Treatments/StatusTag'
import { defaultColumnOptions } from 'utils/table'

const columnHelper = createColumnHelper<TreatmentsType>()
export const treatmentsColumns = [
  columnHelper.accessor('patientFirstName', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Patient name',
    cell: ({ row: { original } }) => {
      return `${original.patientFirstName} ${original.patientLastName}`
    },
  }),
  columnHelper.accessor('week', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Week',
    enableColumnFilter: false,
    enableSorting: false,
    meta: { width: '20%' },
    cell: info => {
      const { week, year } = info.row.original
      return `${year} - ${i18next.t('Week')} ${week}`
    },
  }),
  columnHelper.accessor('proceduresCount', {
    ...defaultColumnOptions<TreatmentsType>(),
    meta: { width: '20%' },
    enableColumnFilter: false,
    header: 'Procedures count',
  }),
  columnHelper.accessor('visit', {
    ...defaultColumnOptions<TreatmentsType>(),
    meta: { width: '20%' },
    enableColumnFilter: false,
    header: 'Visit',
    cell: ({ row: { original } }) => {
      return original.visit ?? '--'
    },
  }),
  columnHelper.accessor('status', {
    ...defaultColumnOptions<TreatmentsType>(),
    header: 'Status',
    enableColumnFilter: false,
    meta: { width: '20%' },
    cell: ({ row: { original } }) => {
      return <StatusTag status={original.status} />
    },
  }),
]
