import { styled } from '@weareredlight/design-system'

export const BigCalendarStylesOverride = styled('div', {
  width: '100%',
  height: '100%',

  // Calendar
  '.rbc-calendar': {
    gap: '$lg',

    // Toolbar
    '.rbc-toolbar': {
      height: '34px',
      marginBottom: 'unset',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',

      '> span:last-child': {
        display: 'none',
      },

      '.rbc-toolbar-label': {
        include: 'paragraph',
        color: '$accent',
        flexGrow: 'unset',
      },

      '.rbc-btn-group': {
        display: 'flex',
        border: '1px solid $neutral400',
        borderRadius: '$xsm',
        overflow: 'hidden',

        button: {
          appearance: 'none',
          outline: 'none',
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$xxsm',
          padding: '$xxsm $lg',
          cursor: 'pointer',
          border: 'none',
          backgroundColor: 'transparent',
          color: '$neutral700',

          '&:nth-child(even)': {
            zIndex: 2,
            borderLeft: '1px solid $neutral400',
            borderRight: '1px solid $neutral400',
          },
          '&:hover': {
            backgroundColor: '$neutral100',
            borderColor: '$neutral400',
            color: '$neutral700',
          },
          '&:disabled': {
            pointerEvents: 'none',
          },
          '&.rbc-active': {
            backgroundColor: '$neutral100',
            borderColor: '$neutral400',
            color: '$neutral700',
            boxShadow: 'unset',
          },
        },
      },
    },

    //Week view
    '.rbc-time-view': {
      borderRadius: '$sm',
      overflow: 'hidden',
      '.rbc-time-header': {
        backgroundColor: '$neutral100',

        '.rbc-header': {
          padding: '$xxsm 0',
          borderColor: '$neutral200',

          '&.rbc-today': {
            backgroundColor: '$neutral200',
          },
        },
        '.rbc-time-header-gutter': {
          width: '48px !important',
          maxWidth: '48px !important',
        },
      },
      '.rbc-time-content': {
        borderTop: 0,
      },
    },

    // Month view
    '.rbc-month-view': {
      borderRadius: '$sm',
      overflow: 'hidden',

      '.rbc-month-header': {
        backgroundColor: '$neutral100',

        '.rbc-header': {
          padding: '$xxsm 0',
        },
      },
    },
  },

  // Hide all day events
  '& .rbc-allday-cell': {
    display: 'none',
  },

  // Month + week header text
  '& div.rbc-header span': {
    include: 'subHeading',
    color: '$neutral900',
  },

  // Month day text
  '& div.rbc-date-cell > button': {
    include: 'heading6',
    color: '$neutral600',
  },

  // Hour text
  '& div.rbc-time-slot span': {
    include: 'textBlock',
    color: '$neutral700',
  },

  // Event hour text
  '& div.rbc-event-label, & div.rbc-slot-selecting': {
    include: 'heading6',
    color: '$white',
  },

  // Event text
  '& div.rbc-event-content': {
    include: 'heading7',
    color: '$white',
  },

  '.rbc-events-container': {
    margin: '0 !important',
    '.rbc-event, .rbc-selected': {
      padding: 0,
      backgroundColor: '$primary400 !important',

      '.rbc-event-label': {
        width: '100% !important',
        backgroundColor: '$primary500 !important',
        include: 'subHeadingSmall',
        padding: '$xxxsm',
        borderRadius: '$xxsm $xxsm 0 0',
      },
      '.rbc-event-content': {
        include: 'microCopy',
        padding: '$xxxsm',
      },
    },
    '.rbc-selected, .rbc-addons-dnd-drag-preview': {
      backgroundColor: '$primary500 !important',
      '.rbc-event-label': {
        backgroundColor: '$primary !important',
      },
    },
  },
  '.rbc-current-time-indicator': {
    backgroundColor: '$accent',
    zIndex: 0,
  },
  '.rbc-row-segment': {
    '.rbc-event': {
      backgroundColor: '$primary500 !important',
      '&.rbc-selected': {
        backgroundColor: '$primary !important',
      },
    },
  },

  '.rbc-event': {
    overflow: 'hidden !important',
    display: 'unset !important',

    '.event-details': {
      height: '100%',
      overflow: 'hidden',
    },

    '.event-trigger': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0,

      button: {
        all: 'unset',
        width: '100%',
        height: '100%',
      },
    },

    '&.inactive-event': {
      backgroundColor: '$neutral500 !important',
      borderColor: '$neutral500 !important',
      '.rbc-event-label': {
        backgroundColor: '$neutral800 !important',
      },
    },
    '&.current-appointment': {
      backgroundColor: '$accent500 !important',
      borderColor: '$accent500 !important',
      '.rbc-event-label': {
        backgroundColor: '$accent800 !important',
      },
    },
  },

  '.rbc-day-slot': {
    cursor: 'pointer',
    '.rbc-timeslot-group': {
      '&:has(.inactive-slot)': {
        borderColor: '$neutral400',
      },
      '.inactive-slot': {
        backgroundColor: '$neutral300',
        borderColor: '$neutral400',
      },
      '&:has(.scheduled-slot)': {
        borderColor: '$neutral400',
      },
      '.scheduled-slot': {
        backgroundColor: '$neutral200',
        borderColor: '$neutral400',
      },
    },
  },
})
