export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  // primary colors
  primary100: '#CCD6E7', // 90% tint
  primary200: '#AABAD7', // 80% tint
  primary300: '#8097C3', // 60% tint
  primary400: '#5575AF', // 40% tint
  primary500: '#2B539B', // 20% tint
  primary: '#003087', // base
  primary600: '#002871', // 20% shade
  primary700: '#00205A', // 40% shade
  primary800: '#001844', // 60% shade
  primary900: '#00102D', // 80% shade
  // accent colors
  accent100: '#DDF5FF', // 90% tint
  accent200: '#C7EEFF', // 80% tint
  accent300: '#ABE5FF', // 60% tint
  accent400: '#8FDCFF', // 40% tint
  accent500: '#73D4FF', // 20% tint
  accent: '#57CBFF', // base
  accent600: '#49A9D4', // 20% shade
  accent700: '#3A87AA', // 40% shade
  accent800: '#2C6680', // 60% shade
  accent900: '#1D4455', // 80% shade
  // neutral colors
  neutral100: '#E4E4E4', // 80% tint
  neutral200: '#D3D3D3', // 60% tint
  neutral300: '#BCBCBC', // 40% tint
  neutral400: '#A6A6A6', // 20% tint
  neutral500: '#909090', // 10% tint
  neutral: '#7A7A7A', // base
  neutral600: '#666666', // 20% shade
  neutral700: '#515151', // 40% shade
  neutral800: '#3D3D3D', // 60% shade
  neutral900: '#292929', // 80% shade
  // success colors
  success100: '#DFF5E2', // 80% tint
  success200: '#B0E6B7', // 60% tint
  success300: '#96DEA0', // 40% tint
  success400: '#7BD688', // 20% tint
  success: '#61CE70', // base
  success500: '#41894B', // 20% shade
  success600: '#204525', // 40% shade
  // danger colors
  danger100: '#FAD7D7', // 80% tint
  danger200: '#F29C9A', // 60% tint
  danger300: '#EE7B78', // 40% tint
  danger400: '#E95A57', // 20% tint
  danger: '#E53935', // base
  danger500: '#992623', // 20% shade
  danger600: '#4C1312', // 40% shade
  // status colors
  statusNew: '#d2d2d2',
  statusToBeScheduled: '#be94dc',
  statusScheduled: '#7EACFC',
  statusInProgress: '#cdd99b',
  statusDischarged: '#7AF6B8',
  statusCancelled: '#EDA9AA',
}
