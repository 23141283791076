export const splitCamelCase = (identifier: string) => {
  return identifier?.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const getName = (
  name?: Record<string, unknown> | null,
  prefix?: string,
) => {
  if (!name) return '--'
  const firstName = name[prefix ? prefix + 'FirstName' : 'firstName']
  const lastName = name[prefix ? prefix + 'LastName' : 'lastName']
  return `${firstName} ${lastName}`
}
