import { Flex, Text } from '@weareredlight/design-system'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  AppointmentInputType,
  AppointmentOnCalendarType,
} from 'types/appointments'
import type { ProcedureType } from 'types/procedures'
import type { ProviderType } from 'types/providers'
import type { ResourcesType } from 'types/treatments'
import type { FormType } from 'types/types'
import type { Specialities } from 'utils/enums'

import { StyledAppointmentForm } from './appointment-form.styles'

import {
  ApptFields,
  mapProvider,
  formatHeader,
} from 'components/Appointments/utils'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import {
  ControlledCheckboxes,
  ControlledRadioButtons,
  Form,
} from 'components/Form'
import { ProceduresFields } from 'components/Procedures/utils'
import { Certification } from 'utils/enums'
import {
  APPOINTMENT_FORMAT,
  format,
  timeStringToHoursAndMinutes,
} from 'utils/time'

export type AppointmentFormType = FormType<AppointmentInputType> & {
  procedure: ProcedureType
  event: AppointmentOnCalendarType | null
  resources: ResourcesType | null
  isLoading: boolean
  setResourcesSelected: (key: { room?: string; providers: string[] }) => void
}

const AppointmentForm = ({
  form,
  procedure,
  event,
  resources,
  isLoading,
  setResourcesSelected,
  ...props
}: AppointmentFormType) => {
  const { t } = useTranslation()

  const startDate = form.watch(ApptFields.START_TIME)
  const endDate = form.watch(ApptFields.END_TIME)
  const room = form.watch(ApptFields.ROOM_ID)
  const providers = form.watch(ApptFields.PROVIDER_IDS)

  const roomsOptions = useMemo(
    () =>
      resources?.rooms?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) || [],
    [resources?.rooms],
  )

  const requiredCertifications = useMemo(() => {
    const filteredCerticatiosn = procedure?.[
      ProceduresFields.REQUIRED_PROVIDERS
    ]
      ?.map(({ certification }) => certification)
      .sort()
    return Array.from(new Set(filteredCerticatiosn)) || []
  }, [procedure])

  const requiredSpecialities = useMemo(
    () =>
      procedure?.[ProceduresFields.REQUIRED_PROVIDERS]?.filter(
        provider => provider.certification === Certification.DOCTOR,
      ) || [],
    [procedure],
  )

  const filterProviders = useCallback(
    (certification: Certification, speciality?: Specialities) => {
      const filterByCertificationAndSpecialty = speciality
        ? (provider: ProviderType) =>
            provider?.certification === certification &&
            provider?.specialities?.includes(speciality)
        : (provider: ProviderType) => provider?.certification === certification

      const availableProvidersList = resources?.providers
        .filter(filterByCertificationAndSpecialty)
        .map(mapProvider)

      return availableProvidersList || []
    },
    [resources?.providers],
  )

  const getProviderHeader = useCallback(
    (certification: Certification) => {
      const count = procedure?.providerTypes?.reduce((acc, cur) => {
        return cur.certification === certification ? acc + cur.count : acc
      }, 0)

      return formatHeader(t(certification), count)
    },
    [procedure?.providerTypes, t],
  )

  useEffect(() => {
    const { start, end } = event || {}
    if (start && end) {
      form.setValue(`${ApptFields.START_TIME}`, format(start))
      form.setValue(`${ApptFields.END_TIME}`, format(end))
    }
  }, [event, form])

  useEffect(() => {
    setResourcesSelected({ room, providers })
  }, [providers, room, setResourcesSelected])

  return (
    <StyledAppointmentForm>
      <Form<AppointmentInputType> form={form} {...props}>
        <Flex direction="column" gap="lg" css={{ width: '100%' }}>
          <Flex direction="column" align="start" css={{ width: '100%' }}>
            <Flex justify="start" gap="sm" css={{ width: '100%' }}>
              <EntityDetail label={t('Duration')}>
                {timeStringToHoursAndMinutes(
                  procedure[ProceduresFields.PREFIX][
                    ProceduresFields.MAIN_TIME
                  ],
                )}
              </EntityDetail>
              <EntityDetail label={t('Starting at')}>
                {startDate ? format(startDate, APPOINTMENT_FORMAT) : '--'}
              </EntityDetail>
              <EntityDetail label={t('Ending at')}>
                {endDate ? format(endDate, APPOINTMENT_FORMAT) : '--'}
              </EntityDetail>
            </Flex>
            <Text variant="microCopy" color="danger">
              {form.formState.errors[ApptFields.START_TIME]?.message}
            </Text>
          </Flex>
          <Card
            extraClasses="full-width no-padding appointment-form-card"
            isLoading={isLoading}
          >
            <Flex direction="column" align="start" gap="lg">
              <Flex justify="spaceBetween" css={{ width: '100%' }}>
                <Text variant="h5" color="accent">
                  {t('Available Rooms')}
                </Text>
                <Text variant="microCopy" color="danger">
                  {form.formState.errors[ApptFields.ROOM_ID]?.message}
                </Text>
              </Flex>
              <ControlledRadioButtons
                name={ApptFields.ROOM_ID}
                options={roomsOptions}
                noOptionsMessage={t('No rooms available')}
              />
            </Flex>
          </Card>
          <Card
            extraClasses="full-width no-padding appointment-form-card"
            isLoading={isLoading}
          >
            <Flex direction="column" align="start" gap="lg">
              <Flex justify="spaceBetween" css={{ width: '100%' }}>
                <Text variant="h5" color="accent">
                  {t('Available Providers')}
                </Text>
                <Text variant="microCopy" color="danger">
                  {form.formState.errors[ApptFields.PROVIDER_IDS]?.message}
                </Text>
              </Flex>
              <Flex direction="column" align="start" gap="xxlg">
                {requiredCertifications.map(certification => {
                  if (!certification) return null
                  return (
                    <Flex
                      key={certification}
                      direction="column"
                      align="start"
                      gap="xsm"
                    >
                      <Text variant="subHeading">
                        {getProviderHeader(certification)}
                      </Text>

                      {/* Show Doctors by Speciality */}
                      {certification === Certification.DOCTOR ? (
                        requiredSpecialities.map(({ speciality, count }) => {
                          if (!speciality) return null
                          return (
                            <Flex
                              key={speciality}
                              direction="column"
                              align="start"
                              gap="xxsm"
                            >
                              {/* If there are required providers for a speciality, show the necessary amount */}
                              <Text variant="h6" color="neutral600">
                                {formatHeader(t(speciality), count)}
                              </Text>
                              <ControlledCheckboxes
                                name={ApptFields.PROVIDER_IDS}
                                options={filterProviders(
                                  certification,
                                  speciality,
                                )}
                                noOptionsMessage={t('No providers available')}
                              />
                            </Flex>
                          )
                        })
                      ) : (
                        <ControlledCheckboxes
                          name={ApptFields.PROVIDER_IDS}
                          options={filterProviders(certification)}
                          noOptionsMessage={t('No providers available')}
                        />
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>
          </Card>
          {procedure.schedulingRules && (
            <Card
              extraClasses="full-width no-padding appointment-form-card"
              isLoading={isLoading}
            >
              <Flex direction="column" align="start" gap="lg">
                <Text variant="h5" color="accent">
                  {t('Scheduling Rules')}
                </Text>
                <Text variant="textBlock" color="neutral800">
                  {procedure.schedulingRules}
                </Text>
              </Flex>
            </Card>
          )}
        </Flex>
      </Form>
    </StyledAppointmentForm>
  )
}

export default AppointmentForm
