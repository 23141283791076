import * as yup from 'yup'

import type {
  ProviderInputType,
  ProviderScheduleInputType,
} from 'types/providers'

import { ProviderFields } from 'components/Providers/ProviderForm'
import { ShiftsFields } from 'components/Providers/utils'
import i18next from 'i18n'
import { Certification, Specialities } from 'utils/enums'

export const ProviderInputSchema: yup.Schema<ProviderInputType> = yup
  .object()
  .shape({
    [ProviderFields.FIRSTNAME]: yup
      .string()
      .required(i18next.t('First name is required')),
    [ProviderFields.LASTNAME]: yup
      .string()
      .required(i18next.t('Last name is required')),
    [ProviderFields.CERTIFICATION]: yup
      .mixed<Certification>()
      .oneOf(Object.values(Certification))
      .required(i18next.t('Certification is required')),
    [ProviderFields.SPECIALITIES]: yup.array(
      yup.mixed<Specialities>().oneOf(Object.values(Specialities)).defined(),
    ),
    [ProviderFields.ORGANIZATION_ID]: yup.string().required(),
  })

export const ProviderShiftInputSchema: yup.Schema<ProviderScheduleInputType> =
  yup.object().shape({
    [ShiftsFields.DATE]: yup.string(),
    [ShiftsFields.PROVIDER_ID]: yup.string().required(),
    [ShiftsFields.START_TIME]: yup
      .string()
      .required(i18next.t('Start time is required')),
    [ShiftsFields.END_TIME]: yup
      .string()
      .required(i18next.t('End time is required'))
      .test(
        'is-after',
        i18next.t('End time must be greater than start time'),
        function(value) {
          const { [ShiftsFields.START_TIME]: startTime } = this.parent
          return value > startTime
        },
      ),
  })
