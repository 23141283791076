import { Button, Text, Flex, Pill } from '@weareredlight/design-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProcedureType } from 'types/procedures'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { ProceduresFields } from 'components/Procedures/utils'
import Table from 'components/Table'
import { useRequest } from 'hooks/useRequest'
import { GenericPath, RootPath } from 'router/enums'
import { providersColumns } from 'utils/tables/providers'
import { timeStringToHoursAndMinutes } from 'utils/time'

const ProcedureView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const {
    data: procedure,
    doRequest: getProcedure,
    isLoading,
  } = useRequest<ProcedureType, { id: string }>(api.getProcedure)

  const requiresCellBank = useMemo(
    () => Boolean(procedure?.[ProceduresFields.NUMBER_OF_CELLS]),
    [procedure],
  )

  useEffect(() => {
    if (id) getProcedure({ id })
  }, [getProcedure, id])

  return (
    <PageTemplate
      title={t('Procedure Details')}
      goBackTo={RootPath.PROCEDURES}
      numberOfElements={2}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card isLoading={isLoading}>
        <Flex gap="xxxlg" direction="column" align="start">
          <Text variant="h2" color="primary800">
            {procedure?.[ProceduresFields.NAME]}
          </Text>
          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Active')}>
              <Pill
                variant={
                  procedure?.[ProceduresFields.IS_ACTIVE] ? 'success' : 'error'
                }
              >
                {procedure?.[ProceduresFields.IS_ACTIVE] ? t('Yes') : t('No')}
              </Pill>
            </EntityDetail>
            <Flex gap="xsm">
              <EntityDetail label={t('Product Specialty')}>
                {procedure?.[ProceduresFields.PRODUCT_SPECIALTY] || '--'}
              </EntityDetail>
              <EntityDetail label={t('Medical Service Type')}>
                {procedure?.[ProceduresFields.MEDICAL_SERVICE_TYPE] || '--'}
              </EntityDetail>
            </Flex>
            <Flex gap="xsm">
              <EntityDetail label={t('Procedure Type')}>
                {procedure?.[ProceduresFields.PROCEDURE_TYPE] || '--'}
              </EntityDetail>
              <EntityDetail label={t('Provider Type')}>
                {procedure?.[ProceduresFields.PROVIDER_TYPE] || '--'}
              </EntityDetail>
            </Flex>
            <Flex gap="xsm">
              <EntityDetail label={t('Preparation Type')}>
                {procedure?.[ProceduresFields.PREPARATION_TYPE] || '--'}
              </EntityDetail>
              <EntityDetail label={t('CRM Code')}>
                {procedure?.[ProceduresFields.CRM_CODE] || '--'}
              </EntityDetail>
            </Flex>
            <Flex gap="xsm">
              <EntityDetail label={t('Pre Preparation')}>
                {procedure?.[ProceduresFields.PRE_PREPARATION]
                  ? timeStringToHoursAndMinutes(
                      procedure?.[ProceduresFields.PRE_PREPARATION],
                    )
                  : '--'}
              </EntityDetail>
              <EntityDetail label={t('Post Preparation')}>
                {procedure?.[ProceduresFields.POST_PREPARATION]
                  ? timeStringToHoursAndMinutes(
                      procedure?.[ProceduresFields.POST_PREPARATION],
                    )
                  : '--'}
              </EntityDetail>
            </Flex>
            <Flex gap="xsm">
              <EntityDetail label={t('Duration')}>
                {procedure?.[ProceduresFields.PREFIX]?.[
                  ProceduresFields.MAIN_TIME
                ]
                  ? timeStringToHoursAndMinutes(
                      procedure?.[ProceduresFields.PREFIX]?.[
                        ProceduresFields.MAIN_TIME
                      ],
                    )
                  : '--'}
              </EntityDetail>
              <EntityDetail label={t('Duration Before')}>
                {procedure?.[ProceduresFields.PREFIX]?.[
                  ProceduresFields.BEFORE_TIME
                ]
                  ? timeStringToHoursAndMinutes(
                      procedure?.[ProceduresFields.PREFIX]?.[
                        ProceduresFields.BEFORE_TIME
                      ],
                    )
                  : '--'}
              </EntityDetail>
            </Flex>
            <Flex gap="xsm">
              <EntityDetail label={t('Requires Cell Bank')}>
                <Pill variant={requiresCellBank ? 'success' : 'error'}>
                  {requiresCellBank ? t('Yes') : t('No')}
                </Pill>
              </EntityDetail>
              {requiresCellBank && (
                <EntityDetail label={t('Number of Stem Cells')}>
                  {procedure?.[ProceduresFields.NUMBER_OF_CELLS] || '--'}
                </EntityDetail>
              )}
            </Flex>
            <EntityDetail label={t('Description')}>
              {procedure?.[ProceduresFields.DESCRIPTION] || '--'}
            </EntityDetail>
            <EntityDetail label={t('Scheduling Rules')}>
              {procedure?.[ProceduresFields.RULES] || '--'}
            </EntityDetail>
          </Flex>
        </Flex>
      </Card>
      <Card>
        <Flex gap="xxlg" direction="column" align="start">
          <Text variant="h3" color="accent">
            {t('Required Providers')}
          </Text>
          <Table
            data={procedure?.[ProceduresFields.REQUIRED_PROVIDERS] ?? []}
            columns={providersColumns}
            totalCount={
              procedure?.[ProceduresFields.REQUIRED_PROVIDERS]?.length || 0
            }
            totalPages={0}
          />
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default ProcedureView
