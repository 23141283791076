import { TimePicker } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'

export enum PickerTypes {
  DURATION = 'duration',
  TIME = 'time',
}

type ControlledTimePickerProps = {
  name: string
  label?: string
  description?: string
  error?: string | null
  type: PickerTypes
  fullWidth?: boolean
  disabled?: boolean
}

export const ControlledTimePicker = ({
  name,
  label,
  description,
  error,
  type,
  disabled = false,
  fullWidth = false,
}: ControlledTimePickerProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TimePicker
          label={label}
          description={description}
          error={error}
          type={type}
          value={value}
          onChange={time => {
            if (!time || time === '--:--:00') {
              onChange(null)
            } else {
              onChange(time)
            }
          }}
          disabled={disabled}
          fullWidth={fullWidth}
        />
      )}
    />
  )
}
