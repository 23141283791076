import { PlusIcon } from '@radix-ui/react-icons'
import { Button, Text, Flex } from '@weareredlight/design-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { PatientType } from 'types/patients'

import PatientTreatmentsList from '../../components/Patients/PatientTreatmentsList'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { PatientFields } from 'components/Patients/PatientForm'
import { useRequest } from 'hooks/useRequest'
import { RootPath, GenericPath, TreatmentsPath } from 'router/enums'
import { getName, splitCamelCase } from 'utils/text'

const PatientView = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const {
    data: patient,
    doRequest: getPatient,
    isLoading,
  } = useRequest<PatientType, { id: string }>(api.getPatient)

  const dateofbirth = useMemo(() => {
    if (!patient?.[PatientFields.DATE_OF_BIRTH]) return '--'
    const date = new Date(patient?.[PatientFields.DATE_OF_BIRTH])
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    ).toISOString()
    return localDate.split('T')[0]
  }, [patient])

  useEffect(() => {
    if (id) getPatient({ id })
  }, [getPatient, id])

  return (
    <PageTemplate
      title={t('Patient Details')}
      goBackTo={RootPath.PATIENTS}
      numberOfElements={2}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card isLoading={isLoading}>
        <Flex direction="column" align="start" gap="xxxlg">
          <Flex align="start" justify="start" gap="xsm">
            <Text variant="h2" color="primary800">
              {getName(patient)}
            </Text>
          </Flex>

          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Type')}>
              {patient?.[PatientFields.PATIENT_TYPE]
                ? splitCamelCase(patient[PatientFields.PATIENT_TYPE])
                : ''}
            </EntityDetail>
            <EntityDetail label={t('Age')}>
              {patient?.[PatientFields.AGE] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Date of birth')}>
              {dateofbirth}
            </EntityDetail>
            <EntityDetail label={t('Email')}>
              {patient?.[PatientFields.EMAIL]}
            </EntityDetail>
            <EntityDetail label={t('Mobile Phone')}>
              {patient?.[PatientFields.PHONE_NUMBER] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Home Phone')}>
              {patient?.[PatientFields.HOME_PHONE] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Salesforce ID')}>
              {patient?.[PatientFields.EXTERNAL_PATIENT_ID] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Patient Identifier')}>
              {patient?.[PatientFields.PATIENT_IDENTIFIER] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Document Type')}>
              {patient?.[PatientFields.DOCUMENT_TYPE]
                ? splitCamelCase(patient[PatientFields.DOCUMENT_TYPE])
                : '--'}
            </EntityDetail>
            <EntityDetail label={t('Document Number')}>
              {patient?.[PatientFields.DOCUMENT_NUMBER]}
            </EntityDetail>
            <EntityDetail label={t('Consents to SMS Messaging')}>
              {patient?.[PatientFields.CONSENT_TO_SMS] ? t('Yes') : t('No')}
            </EntityDetail>
          </Flex>

          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Country')}>
              {patient?.[PatientFields.COUNTRY] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Street')}>
              {patient?.[PatientFields.STREET] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('State')}>
              {patient?.[PatientFields.STATE] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('City')}>
              {patient?.[PatientFields.CITY] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Postal Code')}>
              {patient?.[PatientFields.POSTAL_CODE] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Gender')}>
              {patient?.[PatientFields.GENDER]
                ? splitCamelCase(patient[PatientFields.GENDER])
                : '--'}
            </EntityDetail>
            <EntityDetail label={t('Concierge Name')}>
              {patient?.[PatientFields.CONCIERGE_NAME] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Concierge Phone Number')}>
              {patient?.[PatientFields.CONCIERGE_PHONE_NUMBER] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Advocate Name')}>
              {patient?.[PatientFields.ADVOCATE_NAME] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Advocate Phone Number')}>
              {patient?.[PatientFields.ADVOCATE_PHONE_NUMBER] ?? '--'}
            </EntityDetail>
            <EntityDetail label={t('Stage')}>
              {patient?.[PatientFields.STAGE]
                ? splitCamelCase(patient[PatientFields.STAGE])
                : '--'}
            </EntityDetail>
          </Flex>
        </Flex>
      </Card>
      <Card extraClasses="no-padding">
        <Flex direction="column" gap="xxlg" css={{ padding: '1.5rem' }}>
          <Flex justify="spaceBetween" css={{ width: '100%' }}>
            <Text variant="h3" color="accent">
              {t('Treatments')}
            </Text>
            <Button
              variant="tertiary"
              iconComponent={() => <PlusIcon />}
              onClick={() =>
                navigate(`${TreatmentsPath.TREATMENTS}/${GenericPath.NEW}`)
              }
            >
              {t('Add Treatment')}
            </Button>
          </Flex>
          <PatientTreatmentsList patientId={id || ''} />
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default PatientView
