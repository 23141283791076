import { alert, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { AppointmentInputType } from 'types/appointments'

import AppointmentForm from './AppointmentForm'

import api from 'api/api'
import { ApptFields } from 'components/Appointments/utils'
import EntityDetail from 'components/EntityDetail'
import { useFormManager } from 'hooks/useFormManager'
import { useSchedule } from 'pages/Schedule/ScheduleAppointment'
import { AppointmentInputSchema } from 'schemas/appointment'

const AppointmentEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    draggedEvent,
    activeAppointment,
    resetActiveProcedure,
    resources,
    isLoadingResources,
    updateTreatment,
    setResourcesSelected,
  } = useSchedule()

  const form = useFormManager<AppointmentInputType>({
    defaultValues: {
      [ApptFields.START_TIME]: activeAppointment?.startTime,
      [ApptFields.END_TIME]: activeAppointment?.endTime,
      [ApptFields.DURATION_BEFORE]: activeAppointment?.durationBefore,
      [ApptFields.TREATMENT_PROCEDURE_ID]:
        activeAppointment?.treatmentProcedure?.id,
      [ApptFields.ROOM_ID]: activeAppointment?.room?.id,
      [ApptFields.PROVIDER_IDS]: activeAppointment?.providers?.map(
        ({ id }) => id,
      ),
    },
    enableReinitialize: true,
    schema: AppointmentInputSchema,
    onCancel: () => {
      resetActiveProcedure()
      navigate('', { replace: true })
    },
    onSubmit: async (input: AppointmentInputType) => {
      if (!activeAppointment) return
      await api.updateAppointment({ id: activeAppointment.id, input })
      resetActiveProcedure()
      navigate('', { replace: true })
      updateTreatment()
      alert.success(
        `${t('Success')}!`,
        `${t('Appointment edited successfully.')}`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  if (!activeAppointment) return null

  return (
    <>
      <Text variant="h2">
        {t('Edit appointment')}
        <Text color="neutral800">
          {' '}
          - {t('for')}{' '}
          <b>{activeAppointment.treatmentProcedure.procedure.name}</b>
        </Text>
      </Text>
      <EntityDetail label={t('Patient')}>
        {activeAppointment.treatmentProcedure.treatment.patientFirstName}{' '}
        {activeAppointment.treatmentProcedure.treatment.patientLastName}
      </EntityDetail>
      <AppointmentForm
        {...form}
        procedure={activeAppointment.treatmentProcedure.procedure}
        event={draggedEvent}
        resources={resources}
        isLoading={isLoadingResources}
        setResourcesSelected={setResourcesSelected}
      />
    </>
  )
}

export default AppointmentEdit
